import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import MuiPhoneNumber from "material-ui-phone-number";
import Skeleton from "@material-ui/lab/Skeleton";

import theme from "../../theme";

import TextField from "../text-field";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Badge, Button } from "@material-ui/core";
import PencilIcon from "../../icons/pencil-icon";

import CardProfile from "./card-profile";
import FooterBtnProfile from "./footer-btn-profile";
import InputFileUpload from "../file-upload/input-file-upload";
import { MAX_MB_ATTACHMENTS } from "../../redux/reducers";
import DateSelectModal from "../date-select-modal/date-select-single-date-modal";
import { formatDateUtc } from "../../utils/dateUtils";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  validatorContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    position: "relative",
    height: "100%",
    width: "100%",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    flex: "1",
    width: "100%",
    marginTop: theme.spacing(2),
    "&:nth-child(odd)": {
      marginRight: theme.spacing(2),
    },
    "&:nth-child(even)": {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      "&:nth-child(odd)": {
        marginRight: 0,
      },
      "&:nth-child(even)": {
        marginLeft: 0,
      },
    },
    "& label + .MuiInput-formControl": {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    "& .MuiButtonBase-root": {
      backgroundColor: theme.palette.grayE5,
    },
    "& .MuiInputBase-root ": {
      borderRadius: 0,
      border: ({ disabled }) => (!disabled ? theme.borders[1] : theme.borders[6]),
      paddingTop: theme.spacing(1 / 2),
      paddingBottom: theme.spacing(1 / 2),
      paddingLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
  },
}));

const PersonalProfile = ({
  user,
  onSubmit,
  userPatchStatus,
  setHasAnyChange,
  onStartUpdateFile = () => {},
  onUpdateFile = () => {},
  onErrorUpdateFile = () => {},
  fileUploadStatus,
}) => {
  const classes = useStyles(theme);
  const { i18n } = useLingui();
  const [valuesProfile, setValuesProfile] = useState({
    name: "",
    lastname: "",
    phone: "",
    email: "",
    birthday: "",
  });
  const [isDisabledSave, setIsDisabledSave] = useState(true);
  const [openDateSelect, setOpenDateSelect] = useState(false);

  const handleInputChange =
    (field) =>
    ({ target }) => {
      setValuesProfile((state) => ({ ...state, [field]: target.value }));
    };

  const handleOnChange = (value) => {
    setValuesProfile((state) => ({ ...state, phone: value }));
  };

  const handleSubmit = () => {
    const payload = getPayload();
    onSubmit({ payload, userId: valuesProfile.id });
  };

  const handleResetForm = () => {
    const formatPhone = (user?.phone).indexOf("+") === 0 ? user?.phone : user?.phone?.length ? `+${user.phone}` : "+";
    setValuesProfile({ ...user, phone: formatPhone });
  };

  const getPayload = () => ({
    name: valuesProfile.name,
    lastname: valuesProfile.lastname,
    phone: valuesProfile.phone,
    email: valuesProfile.email,
    birthday: valuesProfile?.birthday ? formatDateUtc(valuesProfile?.birthday, "YYYY-MM-DD") : "",
  });

  const handleDisplayDate = () => (valuesProfile?.birthday ? formatDateUtc(valuesProfile?.birthday, "DD/MM/YYYY") : "");

  useEffect(() => {
    const payload = getPayload();
    const hasAnyChange = JSON.stringify(valuesProfile) !== JSON.stringify(user);
    setIsDisabledSave(!hasAnyChange);
    setHasAnyChange({ status: hasAnyChange, payload: payload, from: "profile" });
  }, [valuesProfile]);

  useEffect(() => {
    handleResetForm();
  }, [user]);

  return (
    <>
      <CardProfile>
        <Container>
          <Box sx={{ display: { xs: "none", md: "block" }, fontWeight: "bold" }}>
            <Typography variant="h4" color="textPrimary">
              <Trans>Información personal</Trans>
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" mb={1} pt={2}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <Button
                  variant={"contained"}
                  color={"primary"}
                  style={{
                    width: "22px",
                    height: "22px",
                    minWidth: "22px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    padding: 0,
                  }}
                >
                  <label htmlFor="asset-attachment" style={{ cursor: "pointer" }}>
                    <InputFileUpload
                      id="asset-attachment"
                      name="asset-attachment"
                      maxSize={MAX_MB_ATTACHMENTS * 1024 * 1024} // 30MB
                      accept={"image/jpg,image/jpeg,image/png,application/pdf"}
                      style={{ display: "none" }}
                      onStart={onStartUpdateFile}
                      onComplete={onUpdateFile}
                      onError={onErrorUpdateFile}
                      model="user"
                      modelId={user.id}
                      isAvatar
                    />
                    <PencilIcon fill="#FFFFFF" style={{ fontSize: "0.75rem" }} />
                  </label>
                </Button>
              }
            >
              {fileUploadStatus.status === "loading" ? (
                <Skeleton animation="wave" variant="circle" width={theme.spacing(10)} height={theme.spacing(10)} />
              ) : (
                <Avatar alt="Remy Sharp" src={user.avatar} className={classes.avatar} />
              )}
            </Badge>
          </Box>
          {fileUploadStatus.status === "error" && (
            <Box textAlign="center">
              <Typography variant="body1" style={{ color: theme.palette.error.main }}>
                <Trans>Ha ocurrido un error. Por favor intente de nuevo.</Trans>
              </Typography>
            </Box>
          )}
          <ValidatorForm className={classes.validatorContainer} onSubmit={handleSubmit}>
            <Box display="flex" sx={{ flexDirection: { xs: "column", sm: "column", md: "row" } }}>
              <TextField
                label={`${i18n._(t`Nombre`)}`}
                errorMessages={[""]}
                value={valuesProfile?.name}
                InputProps={{
                  placeholder: `${i18n._(t`Nombre`)}`,
                }}
                onChange={handleInputChange("name")}
                validators={["required"]}
                inputContainerClassNames={classes.textContainer}
                fullWidth
              />
              <TextField
                label={`${i18n._(t`Apellidos`)}`}
                errorMessages={["required"]}
                value={valuesProfile?.lastname}
                InputProps={{
                  placeholder: `${i18n._(t`Apellidos`)}`,
                }}
                onChange={handleInputChange("lastname")}
                validators={["required"]}
                inputContainerClassNames={classes.textContainer}
                fullWidth
              />
            </Box>
            <Box display="flex" sx={{ flexDirection: { xs: "column", sm: "column", md: "row" } }} mt={[0, 2]}>
              <DateSelectModal
                open={openDateSelect}
                onClose={() => setOpenDateSelect(false)}
                onSelectDate={(date) => {
                  setValuesProfile((state) => ({ ...state, birthday: date }));
                }}
                date={Date.now()}
                calendarProps={{
                  maxDate: null,
                  minDate: null,
                }}
                title={<Trans>Fecha de nacimiento</Trans>}
                showCleanDates={false}
                textButton={<Trans>Aceptar</Trans>}
                defaultView={"decade"}
              />
              <TextField
                label={`${i18n._(t`Fecha de nacimiento`)}`}
                value={handleDisplayDate()}
                InputProps={{
                  placeholder: `${i18n._(t`dd/mm/yyyy`)}`,
                }}
                inputContainerClassNames={classes.textContainer}
                fullWidth
                onClick={() => setOpenDateSelect(true)}
              />
              <TextField
                label={`${i18n._(t`Dirección de email`)}`}
                errorMessages={[""]}
                value={valuesProfile?.email}
                InputProps={{
                  placeholder: `${i18n._(t`Dirección de email`)}`,
                }}
                onChange={handleInputChange("email")}
                validators={["required"]}
                inputContainerClassNames={classes.textContainer}
                fullWidth
              />
            </Box>
            <Box display="flex" sx={{ flexDirection: "column" }} mt={[0, 2]}>
              <Box mb={1} mt={1}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: theme.palette.black,
                  }}
                >
                  <Trans>Teléfono</Trans>
                </Typography>
              </Box>
              <Box>
                <MuiPhoneNumber
                  value={valuesProfile?.phone}
                  fullWidth
                  inputClass={classes.input}
                  disableAreaCodes
                  onChange={handleOnChange}
                />
              </Box>
            </Box>
          </ValidatorForm>
        </Container>
      </CardProfile>
      <FooterBtnProfile
        onCancel={handleResetForm}
        onSubmit={handleSubmit}
        disabled={isDisabledSave}
        status={userPatchStatus}
      />
    </>
  );
};
export default PersonalProfile;
